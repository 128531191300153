.emptyPage {
  section.first-section {
    display: flex;
    position: relative;
    padding-top: 70px;
    min-height: 100vh;
    width: 100%;

    #rendererContainer {
      position: absolute;
      top: 0;
      z-index: 1;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #0b1524;
      background-image: linear-gradient(135deg, #0b1524, #14314e);
      background-size: 100%;
      overflow: hidden;
    }

    .centered-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: auto auto;
      text-align: center;
      color: #fff;
      z-index: 2;
      h1 {
        font-family: Montserrat;
        font-size: 56px;
        font-weight: 700;
        margin-bottom: 25px;
        line-height: 1.4;
        padding: 0 10px;
        @media screen and (max-width: 720px) {
          font-size: 48px;
        }
        @media screen and (max-width: 480px) {
          font-size: 36px;
        }
      }
      .description {
        font-family: Montserrat;
        font-size: 32px;
        margin-bottom: 70px;
        padding: 0 10px;
        @media screen and (max-width: 720px) {
          font-size: 28px;
        }
        @media screen and (max-width: 480px) {
          font-size: 18px;
        }
      }
      .buttons-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .buttons-first-section {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 60px;
          min-width: 260px;
          margin: 12px 12px;
          padding: 12px 12px;
          background-color: transparent;
          border: 0px solid transparent;
          border-radius: 75px;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
          transition: all 0.3s ease-in;
          &.homePageBtn {
            background-color: #3474d6;
            &:hover {
              background-color: #fff;
              color: #3574d6;
            }
          }
        }
      }
    }
  }
}
